// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Project bar element

import React from "react";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Link } from "@mui/material";
import { Flags } from 'react-feature-flags';
import { TollTwoTone as TollIcon } from "@mui/icons-material";

import { SITE_SUBTITLE, SITE_TITLE } from "#libs/constants"
import { EDIT_MAP_PAGE, PAYMENT_PAGE } from "App";
import AccountMenu from "./AccountMenu";
import { CreditsContext, DrawerContext } from "./Contexts";

import "#styles/components/ProjectBar";
import logo from "#assets/logo.png";


/**
 * Drop down menu for account options
 * @param userCredits Number of credits the user has
 * @param isAuthenticated Whether the user is authenticated
 */
function AccountDisplay({ userCredits, isAuthenticated }: { userCredits: number, isAuthenticated: boolean }) {
  const navigate = useNavigate();
  const handleClickCredits = () => navigate(PAYMENT_PAGE);

  return (
    <div style={{display: "flex", justifyContent: "end", minWidth: 150}}>
      {/* User credit balance */}
      {isAuthenticated ? (
        <div onClick={handleClickCredits} className="clickable mx-2" style={{display: "flex", alignItems: "center"}} >
          <TollIcon fontSize="medium" style={{marginInline: 10}}/>
          <span style={{fontSize: 18 }}>{userCredits}</span>
        </div>
      ) : (
        <div className="clickable mx-2" style={{display: "flex", alignItems: "center"}}>
          <span style={{fontSize: 18 }}>Sign in</span>
        </div>
      )}
      {/* Account menu */}
      <section className="mx-1" style={{justifyContent: "center"}}>
        <AccountMenu />
      </section>
    </div>
  );
}


function HeaderLogo() {
	return (
		<Link
			href={EDIT_MAP_PAGE}
			className="nav__title"
			underline="none" >
			<img className="nav__logo" src={logo} alt="Eduard icon" />
			<h6 style={{fontSize: "1.4em", marginBottom: 0, display: "flex", alignItems: "center"}}>{SITE_TITLE}</h6>
			<span className="nav__subtitle">{SITE_SUBTITLE}</span>
		</Link>
	);
}


/**
 * Projects bar
 * @param { children }
 */
function ProjectBar({ headerContent, children}: { headerContent?: React.ReactNode, children?: React.ReactNode } ) {
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";
  const isAuthenticated = useIsAuthenticated();
  const {userCredits, updatePageCredits} = React.useContext(CreditsContext);

  const [isPanelOpen, setPanel] = React.useState(React.useContext(DrawerContext));
  const toggleDrawer = () => setPanel((showDrawer) => !showDrawer);


  return (
    <>
      <AppBar className="nav">
        <Toolbar variant="dense" className="nav__toolbar glass--dark">
					<HeaderLogo />
          {headerContent}
          {/* Account display */}
          <Flags authorizedFlags={['enableAuthentication']}>
            <AccountDisplay userCredits={userCredits} isAuthenticated={isAuthenticated()} />
          </Flags>
        </Toolbar>
      </AppBar>
      <DrawerContext.Provider value={isPanelOpen}>
        <div className="nav__content">{children}</div>
      </DrawerContext.Provider>
    </>
  );
}

export default ProjectBar;
