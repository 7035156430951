// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Fade in section component

import React from "react";
import "#styles/components/FadeInSection"

/**
 * Fade in scrollable section
 * https://dev.to/selbekk/how-to-fade-in-content-as-it-scrolls-into-view-10j4
 * @param props
 */
function FadeInSection(props: { children: React.ReactNode }) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    const currentElem = domRef.current;

    if (currentElem) {
        observer.observe(currentElem);
        return () => {
            // Unobserve the same element you observed
            observer.unobserve(currentElem);
        };
    }
  }, []);

  return (
    <div 
			className={` fade-in-section ${isVisible ? "is-visible" : ""}`}
			ref={domRef}	
		>
      {props.children}
    </div>
  );
}

export default FadeInSection;
