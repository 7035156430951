import Link from "@mui/material/Link";

import { INFO_PAGE, PRIVACY_POLICY_PAGE } from "App";


/**
 * Attribution and contact info for Monash & Eduard Cloud policies
 */
function AttributionNotice() {
  return (
    <span
			style={{
				position: 'absolute',
				bottom: 0,
				left: 5,
				fontSize: 12,
				textWrap: "nowrap",
			}}>
      {"© 2024 Monash University | "}
      <Link href={PRIVACY_POLICY_PAGE} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
      {" | "}
      <Link href={INFO_PAGE} target="_blank" rel="noopener noreferrer">Contact</Link>
    </span>
  );
}

export default AttributionNotice;
