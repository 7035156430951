// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Theme controller


/**
 * System preference theme controller
 * https://mui.com/material-ui/customization/dark-mode/
 */
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const lightPallet = {
  primary: {
    
  },
  secondary: {

  }
}
const darkPallet = {
  primary: {
    contrastText: "#fff",
  }
}



function ToggleColorMode() {
  const [mode, setMode] = React.useState<'light' | 'dark'>()
}

function ThemeController({ children } : {children: React.ReactNode}) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: "rgb(25, 118, 210)",
          },
          //   main: "#90caf9",  // Default main blue
          //   contrastText: "#000",
          // }
          secondary: {
            main: "rgba(0,0,0,0.25)",
            // dark: "rgba(0,0,0,0.2)",
          }
        },
        // components: {

        // }
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export { ToggleColorMode };
export default ThemeController;