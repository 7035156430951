import * as React from 'react';
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import "#styles/components/NumberInput";


export const NumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <BaseNumberInput
      slotProps={{
        root: {
          className: "number-input__root",
        },
        input: {
          className: "number-input__input",
        },
        incrementButton: {
          className: "number-input__button increment",
          children: '▴',
          disabled: props.disabled,
        },
        decrementButton: {
          className: "number-input__button decrement",
          children: '▾',
          disabled: props.disabled,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});
