// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Ripple effect

import "#styles/components/MapProperties"

/**
 * https://css-tricks.com/how-to-recreate-the-ripple-effect-of-material-design-buttons/
 * @param event Source click event
 * @param radius radius of ripple
 */
function createRipple(event: React.MouseEvent<HTMLElement>, radius: number) {
  const source = event.target;

	if (source instanceof HTMLElement) {
		console.log("Create ripple")
		const circle = document.createElement("span");
		const diameter = 2 * radius;

		circle.style.width = circle.style.height = `${diameter}px`;
		const left = `${source.offsetLeft - (radius)}px`;
		const top = `${source.offsetTop - (radius)}px`;
		circle.style.left = left;
		circle.style.top = top;
		circle.classList.add("ripple"); 
	
		const ripple = source.getElementsByClassName("ripple")[0];
	
		if (ripple) {
			ripple.remove();
		}
	
		source.appendChild(circle);
	}
}

export default createRipple;