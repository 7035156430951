/**
 * Projection drop down selction for OpenTopography.
 */
import { Projection } from "#libs/Project";
import { InputLabel, MenuItem, SelectProps, TextField } from "@mui/material";

const PROJECTION_MENU_ITEMS = new Map<Projection, string>([
	[Projection.MERCATOR, "Mercator"],
	[Projection.GEOGRAPHIC, "Geographic"],
]);


// Digital elevation models
interface ProjectionDropDownProps {
	projection: Projection,
	onChange: (arg0: Projection) => void,
	SelectProps?: Partial<SelectProps> | undefined,
	defaultValue?: Projection,
}

function ProjectionDropdown({
	projection,
	onChange,
	SelectProps,
	defaultValue = Projection.MERCATOR,
}: ProjectionDropDownProps
) {
	const projectionMenuItems = Array.from(PROJECTION_MENU_ITEMS).map(([dem, text]) => {
		return (
			<MenuItem value={dem}>{text}</MenuItem>
		);
	});

	return (
		<TextField
			select
			SelectProps={SelectProps}
			inputProps={{ id: 'projection' }}
			defaultValue={defaultValue}
			value={projection}
			onChange={(e) => onChange(e.target.value as Projection)}
			fullWidth
		>
			<InputLabel sx={{paddingX: 2, opacity: 0.5}}>Projection</InputLabel>
			{projectionMenuItems}
		</TextField>
	);
}

export default ProjectionDropdown;