// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Attribute input slider

import React from "react";
import { Divider, Tooltip, Slider, TextField, Typography } from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { clamp } from "#libs/utils";
import FadeInSection from "#components/FadeSection";
import { NumberInput } from "#components/NumberInput";
import "#styles/components/MapProperties";

export interface AttributeTitleProps {
  paramName?: string;
  description?: string | React.ReactNode;
}
function AttributeTitle({ paramName, description = "" }: AttributeTitleProps) {
  const TooltipDescription = (
    <>
      <Tooltip title={description} placement="bottom-end" style={{zIndex: 3}} >
        <HelpOutlineIcon className="text-right" fontSize="inherit" />
      </Tooltip>
    </>
  );

  return (
    <>
      <div className="attribute-header">
        <h6 className="property-heading">{paramName}</h6>
        {description && TooltipDescription}
      </div>
    </>
  );
}

export interface SinglePropertyProp {
  callback: (value: number) => void;
  value: number;
  label?: string;
  minVal?: number;
  maxVal?: number;
  step?: number;
  disabled?: boolean;
}
function AttributeInput({
  callback,
  value,
  label,
  minVal = 0,
  maxVal = 100,
  step = 1,
  disabled = false,
}: SinglePropertyProp) {
  const handleChange = (val: number) => callback(clamp(val, minVal, maxVal));

  return (
    <>
      <div className="attribute-box">
        {label && <Typography>{label}</Typography>}
        <Slider
          // color="primary"
          value={value}
          onChange={(_, value) => handleChange(value as number)}
          min={minVal}
          max={maxVal}
          step={step}
          disabled={disabled}
        />

        <div className="input-box">
          <NumberInput  // Lacks styling of standard text field
            value={value}
            min={minVal}
            max={maxVal}
            step={step}
            onChange={(e: any, value: any) => handleChange(value as number)}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}

interface AttributeContainerProps {
  paramName?: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  noDivider?: boolean;
}
function AttributeContainer(props: AttributeContainerProps) {
  return (
    <>
      <FadeInSection>
        <div className="px-2 pt-1 pb-2 d-flex flex-column">
          <AttributeTitle
            paramName={props.paramName}
            description={props.description}
          />
          {props.children}
          {!props.noDivider && <Divider className="mt-3 mb-1" sx={{ background: "black" }} />}
        </div>
      </FadeInSection>
    </>
  );
}

export { AttributeInput };
export default AttributeContainer;
