import JSZip from "jszip";
import { v4 as uuidv4 } from 'uuid';
import { getRenderUrl, ImageRender } from "./types";


enum ImageFormat {
	GEOTIFF = "GeoTIFF",
	JPEG = "JPEG",
	PNG = "PNG",
}

/**
 * Get file extension for filetype
 * @param filetype 
 * @returns file extension to use
 */
function getExtension(filetype: ImageFormat) : string {
	switch (ImageFormat[filetype as keyof typeof ImageFormat]) {
    case ImageFormat.GEOTIFF: {
      return ".tif";
    }
    case ImageFormat.JPEG: {
      return ".jpg";
    }
    case ImageFormat.PNG: {
      return ".png";
    }
    default: {
      throw new RangeError(`Invalid image format provided: ${filetype}`);
    }
	}
}

/***
 * Downloads Shading + projects files for selected filetype.
 * @param render		Render response data of generated render.
 * @param filename 	Name of the shading project.
 * @param filetype	Filetype to download shading as.
 */
async function zipShading(render: ImageRender, filename: string, filetype: ImageFormat) {
	console.assert(render, "Invalid render provided");
	filename = (filename) ? filename : uuidv4();
	const zip = new JSZip();

	// Fetch image blob
	const imageContent = await fetch(getRenderUrl(render, filetype)).then(r => r.blob());
	zip.file(filename + getExtension(filetype), imageContent);

	// Also include the geospatial metadata files
	const worldContent = await fetch(render.world).then(r => r.blob());
	zip.file(filename + ".tfw", worldContent);

	const projectionContent = await fetch(render.projection).then(r => r.blob());
	zip.file(filename + ".prj", projectionContent);

	// Generate a ZIP file and download it to browser
	const zipBlob = await zip.generateAsync({ type: "blob" });
	const a = document.createElement("a");
	a.href = URL.createObjectURL(zipBlob);
	a.download = filename + ".zip";
	a.click();
}

export { ImageFormat, zipShading };