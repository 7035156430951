// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Project class definintion

import { Bounds, ProcessingSettings } from "./types";

// Digital elevation models (Open Topography)
enum ElevationModel {
	AW3D30 = "AW3D30",
	NASADEM = "NASADEM",
	SRTMGL1 = "SRTMGL1",
	SRTMGL3 = "SRTMGL3",
}
const DEFAULT_ELEVATION_MODEL = ElevationModel.AW3D30;

enum Projection {
	MERCATOR = "Mercator",
	GEOGRAPHIC = "Geographic"
}
const DEFAULT_PROJECTION = Projection.MERCATOR;

const DEFAULT_PROJECT_NAME = "Untitled map"

class Project {
	_bounds: Bounds | undefined;
	_settings: ProcessingSettings = {
		modelNo: 1,
		lightRotation: 0,
		generalization: 0,
		generalizationDetails: 0,
		aerialPerspective: 50,
		slopeDarkness: 30,
		elevationRangeMin: 0,
		elevationRangeMax: 70,
		flatAreaAmount: 0,
		flatAreaSize: 0,
	};
	_isDemo:boolean = false;
	_elevationModel: ElevationModel = DEFAULT_ELEVATION_MODEL;
	_projection: Projection = DEFAULT_PROJECTION;
	_mapId: string = "";
	_projectName: string = "";

	constructor(){}

	get isDemo(): boolean {
		return this._isDemo;
	}
	set isDemo(isDemo: boolean) {
		this._isDemo = isDemo;
	}

	set elevationModel(model: ElevationModel) {
		this._elevationModel = model;
	}
	get elevationModel(): ElevationModel {
		return this._elevationModel;
	}

	set projection(proj: Projection) {
		this._projection = proj;
	}
	get projection(): Projection {
		return this._projection;
	}

	set projectName(name: string) {
		this._projectName = name;
	}
	get projectName(): string {
		return this._projectName;
	}

	set mapId(id: string) {
		this._mapId = id;
	}
	get mapId(): string {
		return this._mapId;
	}

	get bounds(): any {
		return this._bounds;
	}
	get settings(): any {
		return this._settings;
	}

	set bounds(bounds: L.LatLngBounds) {
		this._bounds = {
			north: bounds.getNorth(),
			south: bounds.getSouth(),
			east: bounds.getEast(),
			west: bounds.getWest(),
		};
	}

	set settings(processSettings: ProcessingSettings) {
		this._settings = processSettings;
	}

	reset(): void {
		this._bounds = undefined;
		this._settings = {
			modelNo: 1,
			lightRotation: 0,
			generalization: 0,
			generalizationDetails: 0,
			aerialPerspective: 50,
			slopeDarkness: 30,
			elevationRangeMin: 0,
			elevationRangeMax: 70,
			flatAreaAmount: 0,
			flatAreaSize: 0,
		};
		this.isDemo = false;
		this._elevationModel = DEFAULT_ELEVATION_MODEL;
		this._projection = DEFAULT_PROJECTION;
		this._mapId = "";
		this._projectName = DEFAULT_PROJECT_NAME;
	}
}



export default Project;
export { Project, ElevationModel, DEFAULT_ELEVATION_MODEL, Projection, DEFAULT_PROJECTION };
