// Written by: FIT3162 CS Team 1
// Last modified: 6/8/24
// Title: Map selection element

import L from "leaflet";
import React, { ReactNode } from "react";
import {
  AttributionControl,
  CircleMarker,
  FeatureGroup,
  MapContainer,
  Rectangle,
  TileLayer,
  Tooltip,
  useMap,
  useMapEvents,
} from "react-leaflet";

import { debounce } from "#libs/utils";

import "#styles/components/MapDisplay";
import "#styles/components/MapSelector";
import "leaflet/dist/leaflet.css";

const MIN_ZOOM: number = 5;
const MAX_ZOOM: number = 20;
const MAP_COUNT: number = 2;
const [LAT_BOUND, LON_BOUND]: number[] = [90, 180];

/**
 * Smaller rectangle overlay
 * https://stackoverflow.com/questions/65119745/get-current-coordinates-on-dragging-react-leaflet
 */
function SelectAreaOverlay({selectionArea: aBounds, setBounds }: {
  selectionArea: L.LatLngBounds;
  setBounds: React.Dispatch<L.LatLngBounds>;
}) {
  const map = useMap();

  const getLatLng = (x: number, y: number) => map.containerPointToLatLng(L.point(x, y));
  const getBounds = (center: L.LatLng, width: number, height: number) => {
    const pt = map.latLngToContainerPoint(center);
    const sw = L.point(pt.x - width / 2, pt.y + height / 2)
    const ne = L.point(pt.x + width / 2, pt.y - height / 2)

    return new L.LatLngBounds(
      map.containerPointToLatLng(sw),
      map.containerPointToLatLng(ne)
    );
  };
  const aWidth = Math.abs(map.latLngToContainerPoint(aBounds.getNorthWest()).x 
                          - map.latLngToContainerPoint(aBounds.getNorthEast()).x);
  const aHeight = Math.abs(map.latLngToContainerPoint(aBounds.getNorthWest()).y 
                            - map.latLngToContainerPoint(aBounds.getSouthWest()).y);

  const [width, setWidth] = React.useState<number>(aWidth);
  const [height, setHeight] = React.useState<number>(aHeight);
  const [center, setCenter] = React.useState<L.LatLng>(aBounds.getCenter());
 
  // Marker positions
  const centerPt = map.latLngToContainerPoint(center);
  const north = getLatLng(centerPt.x, centerPt.y - height / 2);
  const south = getLatLng(centerPt.x, centerPt.y + height / 2);
  const east = getLatLng(centerPt.x + width / 2, centerPt.y);
  const west = getLatLng(centerPt.x - width / 2, centerPt.y);
  const nw = getLatLng(centerPt.x - width / 2, centerPt.y - height / 2);
  const ne = getLatLng(centerPt.x + width / 2, centerPt.y - height / 2);
  const se = getLatLng(centerPt.x + width / 2, centerPt.y + height / 2);
  const sw = getLatLng(centerPt.x - width / 2, centerPt.y + height / 2);
  const bounds = getBounds(center, width, height);

  // Resize selection box
  const resizeXY = (pos: L.LatLng) => {
    const mkrPt = map.latLngToContainerPoint(pos);
    setWidth(Math.abs(centerPt.x - mkrPt.x) * 2)
    setHeight(Math.abs(centerPt.y - mkrPt.y) * 2)
  }
  const resizeX = (pos: L.LatLng) => {
    const mkrPt = map.latLngToContainerPoint(pos);
    setWidth(Math.abs(centerPt.x - mkrPt.x) * 2)
  }
  const resizeY = (pos: L.LatLng) => {
    const mkrPt = map.latLngToContainerPoint(pos);
    setHeight(Math.abs(centerPt.y - mkrPt.y) * 2)
  }

  // Recenter selection box on drag
  useMapEvents({
      drag: () => setCenter(map.getCenter()),
      zoom: () => setCenter(map.getCenter()),
    
  });

  const debounceOnChange = debounce(() => {
    const newBounds = getBounds(center, width, height);
    setBounds(newBounds);
  }, 100);

  React.useEffect(() => {
    debounceOnChange();
  }, [center, width, height])

  return (
    <FeatureGroup>
      <Rectangle
        bounds={bounds}
        color="white"
        weight={4}
        fillColor="transparent"
        dashArray="1"
        className="rectangle-animation"
        bubblingMouseEvents={false}
      />
      <Rectangle
        bounds={bounds}
        color="black"
        weight={1}
        fillColor="transparent"
        dashArray="5"
        className="rectangle-animation"
        bubblingMouseEvents={false}
      />
      <DragableMarker startPos={nw} setDims={resizeXY}/>
      <DragableMarker startPos={ne} setDims={resizeXY}/>
      <DragableMarker startPos={sw} setDims={resizeXY}/>
      <DragableMarker startPos={se} setDims={resizeXY}/>
      <DragableMarker startPos={north} setDims={resizeY}>
        <CoordLabel direction={"top"} pos={bounds.getNorth()} />
      </DragableMarker>
      <DragableMarker startPos={south} setDims={resizeY}>
        <CoordLabel direction={"bottom"} pos={bounds.getSouth()} />  
      </DragableMarker>
      <DragableMarker startPos={west} setDims={resizeX}>
        <CoordLabel direction={"left"} pos={bounds.getWest()} /> 
      </DragableMarker>
      <DragableMarker startPos={east} setDims={resizeX} >
        <CoordLabel direction={"right"} pos={bounds.getEast()} /> 
      </DragableMarker>
    </FeatureGroup>     
  );
}

/**
 * Circle Marker to resize area in AreaSelector
 */
function DragableMarker({startPos, setDims, children }: {
  startPos: L.LatLng;
  setDims: (pos: L.LatLng) => void;
  children?: ReactNode;
}) {
  let position = startPos;
  const [canResize, setResize] = React.useState<boolean>(false)
  const markerRef = React.useRef<L.CircleMarker>(null);
  const map = useMap()

  useMapEvents({
    drag: () => {
      position = startPos;
    },
    mousemove: (e: L.LeafletMouseEvent) => {
      if (canResize) {
        position = e.latlng
        setDims(e.latlng)
      }
    },
    mouseup: (e: L.LeafletMouseEvent) => {
      setResize(false);
      map.dragging.enable()
    }
  });

  const eventHandlers: L.LeafletEventHandlerFnMap = {
    mousedown: (e: L.LeafletMouseEvent) => {
      map.dragging.disable()
      setResize(true);
    },
  }

  return (
    <CircleMarker
      bubblingMouseEvents={false}
      ref={markerRef}
      eventHandlers={eventHandlers}
      center={position}
      color="white"
      fillColor="cornflowerblue"
      radius={10}
      fillOpacity={100}
    >
      {}
      {children}
    </CircleMarker>
  );
}

/**
 * Decimal degrees to degrees, minutes, seconds
 * @param dd Decimal degrees 
 */
function toDMS(dd: number) : string {
  const d = Math.floor(dd);
  const m = Math.floor((dd - d) * 60);
  const s = ((dd - d) * 60 - m) * 60;
  return `${d}° ${m}' ${s.toFixed(2)}"`;
}

function getDirectionChar(pos: number, direction: L.Direction) {
    const LAT_DIR = ['top', 'bottom'];
    const LON_DIR = ['left', 'right'];

    if (LAT_DIR.includes(direction)) {
      return pos >= 0 ? 'N' : 'S';
    }
    else if (LON_DIR.includes(direction)) {
      return pos >= 0 ? 'E' : 'W';
    }
    else {
      return ""
    }
}

function CoordLabel({
  pos,
  direction,
}: {
  pos: number;
  direction: L.Direction;
}) {
  const formatPos = toDMS(Math.abs(pos));
  return (
    <Tooltip direction={direction} permanent>
      {`${formatPos} ${getDirectionChar(pos, direction)}`}
    </Tooltip>
  );
}

/**
 * Maps selector element
 * @param {
 *   setBounds,
 * } 
 */
function MapSelector({ bounds, setBounds }: {
  bounds: L.LatLngBounds
  setBounds: React.Dispatch<L.LatLngBounds>;
}) {
  const mapRef = React.useRef<L.Map>(null);
  const mapBounds = new L.LatLngBounds([
    [-LAT_BOUND, -LON_BOUND * MAP_COUNT],
    [LAT_BOUND, LON_BOUND * MAP_COUNT],
  ]);
  const defaultZoom = 10;

  return (
      <MapContainer
        id="map"
        ref={mapRef}
        className="map-container"
        center={bounds.getCenter()}
        maxBounds={mapBounds}
        maxBoundsViscosity={0.5}
        zoom={defaultZoom}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        preferCanvas // Canvas render (supposed performance improvement)
        attributionControl={false}
      >
        <TileLayer
          attribution='Hosting by <a href="https://opentopography.org">OpenTopography.org</a><br />&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <SelectAreaOverlay selectionArea={bounds} setBounds={setBounds} />
        <AttributionControl position="bottomleft" prefix={false} />
      </MapContainer>
  );
}

export default MapSelector;
