// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Custom type definitions

import { ImageFormat } from "./ImageExports";

export type ProcessingSettings = {
  modelNo: number;
  lightRotation: number;
  generalization: number;
  generalizationDetails: number;
  aerialPerspective: number;
  slopeDarkness: number;
  elevationRangeMin: number;
  elevationRangeMax: number;
  flatAreaAmount: number;
  flatAreaSize: number;
};

export type Bounds = {
  north: number;
  south: number;
  east: number;
  west: number;
}

export interface ImageRender {
  imageSrc: string;
  imageHash: number;
  geoTiff: string;
  png: string;
  world: string;
  projection: string;
}

// namespace ImageRender {
export function getRenderUrl(render: ImageRender, format: ImageFormat) : string {
  switch (ImageFormat[format as keyof typeof ImageFormat]) {
    case ImageFormat.GEOTIFF: {
      return render.geoTiff;
    }
    case ImageFormat.JPEG: {
      return render.imageSrc;
    }
    case ImageFormat.PNG: {
      return render.png;
    }
    default: {
      throw new RangeError(`Invalid image format provided: ${format}`);
    }
  }
}

export type CreditsRatio = {
  credits: number;
  cost: number;
  currency: string;
};

export interface Props {
  children?: React.ReactNode;
}