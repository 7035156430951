import L from "leaflet";

/**
 * Gets the Area in square meters of selected bounds
 * @param bounds Bounding area to get size of
 * @returns are in squared meters of selected area.
 */
function getAreaSize(bounds: L.LatLngBounds) : number {  // generally used geo measurement function
	const width = bounds.getNorthWest().distanceTo(bounds.getNorthEast());
	const height = bounds.getNorthWest().distanceTo(bounds.getSouthWest());
	return width * height;
}

export { getAreaSize }