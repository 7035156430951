import React from "react";
import {
	Box,
	CircularProgress,
	Typography
} from "@mui/material";


/**
 * 
 * @param text text to display under progress spinner.
 * @param children Progress spinner.
 * @param isActive
 */
function ProgressSpinnerContainer({ text, children }: { 
	text: string;
	children: React.ReactNode;
}) {
	return (
		<div className="rendering-backdrop__container glass--darker" >
			<div style={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				gap: 5,
			}}>
				{children}
				{text}
			</div>
		</div>
	);
}

/***
 * Indicator for action durning rendering/download
 */
function ProgressSpinner({ text }: { text: string }) {
	return (
		<ProgressSpinnerContainer text={`${text} ...`}>
			<CircularProgress />
		</ProgressSpinnerContainer>
	);
}

function AnimatedProgressSpinner({text, isActive = true } : { 
	text: string, 
	isActive?: boolean, 
}) {
	const [downloadDots, setDownloadDots] = React.useState<number>(1);

	// Dots progress during OpenTopography download
	React.useEffect(() => {
		if (!isActive) return;

		const downloadDotsInterval = setInterval(() => {
			setDownloadDots((prevDots: number) => (prevDots === 3) ? 1 : prevDots + 1);
		}, 1000);

		return () => clearInterval(downloadDotsInterval);
	}, [isActive]);

	return (
		<ProgressSpinnerContainer text={`${text} ${".".repeat(downloadDots)}`}>
			<CircularProgress />
		</ProgressSpinnerContainer>
	);
}

/**
 * Circulars progress with label
 * https://mui.com/material-ui/react-progress/
 * @param value	Current progress of the spinner.
 * @param text 	Text to display under spinner.
 */
function LabelledProgressSpinner({ value, text }: { 
	value: number;
	text: string;
}) {
	return (
		<ProgressSpinnerContainer text={`${text} ...`}>
			<Box position="relative" display="inline-flex">
				<CircularProgress variant="determinate" value={value} />
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						children={`${Math.round(value)}%`}
						variant="caption"
						component="div"
						sx={{ color: "var(--text-color)" }}
					/>
				</Box>
			</Box>
		</ProgressSpinnerContainer>
	);
}

export { ProgressSpinner, LabelledProgressSpinner, ProgressSpinnerContainer };